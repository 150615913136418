/* .header {

    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-top: 50px;
} */
@font-face {
font-family: Raine-HorneLight;
src: url(../Fonts/Raine-HorneLight.otf);
}

@font-face {
font-family: Raine-HorneRegular;
src: url(../Fonts/Raine-HorneRegular.otf);
}
.change-font{
font-family: Raine-HorneLight;
}
.ewxAGC,.bKIBs,.hyCIZj,.kxOday,.cWJieo,.bAwseG,.awIya,.foVkWd {
	max-width:100% !important; }
#back_backend{
    font-size: 22px;
}
.dropdown-content a{
	color: #383838 !important;
	padding: 6px 13px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}
.dropdown-content a:hover{
	color: #fcaf17 !important;
}
	
.brand {
    font-size: 1.5em;
}

.navbar {
    display: flex;
}

.nav-item {
    margin: 0 10px;
    color: white;
    text-decoration: none;
}

.dropdown-button {
    display: none;
}

@media (max-width: 600px) {
    .navbar {
        display: none;
    }

    .dropdown-button {
        display: block;
        background-color: #4CAF50;
        color: white;
        border: none;
        cursor: pointer;
    }

    .navbar-expanded {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background-color: #4CAF50;
    }

}

@media (min-width: 360px) and (max-width:650px){
    .guesticon{
  
        margin-left: 68% !important;
    }
}
@media (min-width: 1024px) and (max-width:1424px){
    .header-button{
        display: none;
    }
}
@media only screen and (max-width: 300px){
  .mainLogo{
  width: 70%;
  }
}


