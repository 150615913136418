@font-face {
    font-family: Raine-HorneLight;
    src: url(../../Fonts/Raine-HorneLight.otf);
}

.whatsapp-icon {
    position: fixed;
    bottom: 40px;
    /* Adjust the distance from the bottom as needed */
    right: 20px;
    /* Adjust the distance from the right as needed */
    z-index: 1000;
    /* Ensure the icon appears on top of other content */
    cursor: pointer;
    /* Add a pointer cursor to indicate it's clickable */
}

.whatsapp-icon img {
    width: 80px;
    /* Set the width of the WhatsApp icon */
    height: 70px;
    /* Set the height of the WhatsApp icon */
    border-radius: 50%;
    /* Make it circular, adjust as needed */
}

#whatsapp-text {
    font-family: Raine-HorneLight;
    width: auto;
    padding: 0px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 4px !important;
    font-size: 17px;
}

#whatsapp-main-div {
    background: rgb(241, 241, 241);
    padding: 6px !important;
    border-radius: 38px;
    height: 38px;
}

#whatsapp-image {
    z-index: 2;
    height: 28px;
    width: 28px;
    margin-top: -2px;
    margin-left: 5px;
}