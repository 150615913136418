.main-login-con {
	margin: 60px auto;
  width: 70%;
  padding: 5rem 10px;
  background-color: #222222;
  border-radius: 10px;
}

form.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.login-back-to-home {
  color: white;
  border: 2px solid white;
  background-color: #222222;
  width: 100%;
  border-radius: 50px;
  padding: 10px 0rem !important;
}

.login-back-to-home:focus {
  outline: none;
}
.login-back-to-home:hover {
  border: 2px solid white;
  color: white;
}

.login-btn-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
button.login-btn-upper {
  background: #e49f15;
  width: 80%;
  border-radius: 50px;
	border:none;
	padding: 10px 0rem !important;
}

button.login-btn-upper:hover {
  border-color: none;
}
.login-btn-upper a {
  color: #222222;
  text-decoration: none;
  font-size: 20px;
}

input.login-input {
  height: 3rem;
  border-radius: 50px;
  /* text-indent: 2.5rem; */
  outline: #e49f15 !important;
  border: none;
  width: 100%;
  padding: 0rem 3rem;
}

input.login-input::placeholder {
    margin-left: 2rem;
}

.forget-login {
  color: #9a9a9a;
  font-weight: 50;
}

.forget-username-password a {
  text-decoration: none;
  color: white;
}

.txt2:hover {
  color: #ffc20e;
}


.login-password-con{
  position: relative !important;
}

.password-icon{
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;
    color: #222222;
}

.login-name-con{
    position: relative;
}

.name-icon{
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;
    color: #222222;
}