/*new code imp*/
.rent-page-change{
	width: 87%;
	margin: auto;
}
.change-margin{
	margin-right: 0px !important;
}
.cards_new_icon{
	height:25px;
	width: 25px
}
.cards_new_alter{
	display: flex;
	gap: 7px;
	align-items: center;
	margin-top:30px;
	margin-bottom:30px;
}
.additional_div{
	height: 72px;
}
	
.icons_gap{
gap:20;
}
 div#salemaindiv {
	 width:87%;
    margin: 60px auto;
  }
.bedWidth,.bathtubwidth{
width:25%;
}
.squarftwidth{
width:50%;
}
.filter_file{
    color: #4e5158 !important;
	width: 100%;
	height: 45px;
	border: none;
	padding: 5px;
	margin-top: 3px; 
		outline:none;
		margin-right:5px;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
}

.h-a-v-e-m-o-r-e{
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
}

.h-a-v-e-m-o-r-e:hover{
transform: scale(1.03);
}

  .pagination {
    font-family: Raine-HorneLight; /* Replace 'Your Desired Font' with your chosen font */
  }
.result-info{
	font-family: Raine-HorneLight !important;
	padding: 7px !important;
}
#pro-heading{
font-family: Raine-HorneLight !important;
	font-size: 35px !important;
}
/*Search navbar css*/
.outer{
  font-family: Raine-HorneLight;
  display: flex;
  background: #f3f3f3;
  justify-content: space-between;
}
.SearchBar{
  display: flex; 
}
.search{
	border: none;
  height: 45px;
  border-radius: 0px!important;
  outline: 0;
  width: 400px;
  margin: 10px 5px;
  text-indent: 30px;
  padding: 12px;
}
.sale-page-dropdown {
	width: 400px;
	margin: 0 5px;
}
#outer-main{
	max-width: 1140px;
  justify-content: space-between;
  width: 81% !important;
  padding: 10px 0px;
  display: flex;
  margin: auto;
}
.search-icon{
  width: 15px;
  position: absolute;
  margin: 25px 22px;
  cursor: pointer;
  font-size: 18px;
  
}
.all-btns{
  width: 64%;
  display: flex;
  margin-top: 7px;
}
.button-main{
  font-size: 16px!important;
  border-radius: 0px!important;
  width: 100% !important;
  background: transparent!important;
  color: #616468!important;
  border: 1px solid rgb(201, 199, 199);
}
.button-main:hover{
  background: transparent;
  border: 1px solid rgb(201, 199, 199);
  color: #212529;
}
.drop-section{
  background: #f3f3f3;
  margin-top: 5px;
  border-radius: 0px;
}
.main-btn{
  display: none;
}

@media only screen and (max-width : 400px) {
  .outer{
      width: 100%;
      display: block;
  }
  .mini{
    display: none!important;
  }
  #outer-main{
	  width: 100% !important;
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
  
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 18px;
      width: 62px;
      margin-top: 0px;
      padding: 7px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);
  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 400px) and (max-width : 450px) {
  .outer{
      width: 100%;
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .mini{
    display: none!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
  
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
	  height: 45px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);
  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}


@media only screen and (min-width : 650px) and (max-width : 992px){
 .cardspadding {
    flex: 1 1 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width : 451px) and (max-width : 500px) {
  .outer{
      width: 100%;
      display: block;
  }
  .mini{
    display: none!important;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
  
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mini{
    display: none;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
	  height: 45px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);

  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 501px) and (max-width : 550px) {
  .outer{
      width: 100%;
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
  
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
	  height: 45px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);
  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 551px) and (max-width : 600px) {
  .outer{
      width: 100%;
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
  
  .mini{
    display: none!important;
  }
  .SearchBar{
    padding: 0px 10px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    width: 100%;

  }
  .all-btns{

    width: 100%;
    padding: 10px;
    display: block;

  }
  
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
	  height: 45px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);

  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 601px) and (max-width : 700px) {
  .outer{
    /* border: 2px solid red; */
      width: 100%;
      
      display: block;
  }
  #outer-main{
    margin: auto;
    /* border: 1px solid red; */
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
 
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    margin: auto;
    /* flex-wrap: wrap; */

  }
  .all-btns{
    /* border: 1.5px solid green; */
    width: 100%;
    padding: 10px;
    display: block;

  }
  .mini{
    display: none!important;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
	  height: 45px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);

  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 701px) and (max-width : 799px) {
  .outer{
      width: 100%;
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
 
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .mini{
    display: none!important;
  }
  .button-alter{
    margin-top: 5px;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
      margin-top: 0px;
	  height: 45px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);
  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
	.search{
    width: 95%;
    margin: 0px;
  }
	.sale-page-dropdown{
    width: 95%;
    margin: 10px 0 0;
  }
}
@media only screen and (min-width : 800px) and (max-width : 920px) {
  .outer{
      width: 100%;
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
 
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .button-alter{
    margin-top: 5px;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;

  }
  .mini{
    display: none!important;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);

  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 920px) and (max-width : 1024px) {
  .outer{
      width: 100%;
      
      display: block;
  }
  #outer-main{
    margin: auto;
    display: block!important;
  }
  .search-icon{
    position: absolute!important;
    margin-left: 17px;
    margin-top: 17px;
  }
 
  .mini{
    display: none!important;
  }
  .SearchBar{
    padding: 0px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .button-alter{
    margin-top: 5px;
  }
  .all-btns{
    width: 100%;
    padding: 10px;
    display: block;
  }
  .drop-section{
      width: 100%;
  }
  .button-alter{
    cursor: pointer;
    font-size: 22px;
      width: 80px;
      margin-top: 0px;
      margin-left: 10px;
      padding: 7px 13px;
      border-radius: 0px;
      background: transparent;
      color: #212529;
      border: 1px solid rgb(201, 199, 199);
  }
  .button-main{
      margin-top: 10px;
      width: 100%!important;
  }
  .main-btn{
    padding: 0px!important;
    display: flex;
  }
}
@media only screen and (min-width : 1025px) and (max-width : 1100px){
  .outer{
    font-family: Raine-HorneLight;
    display: flex;
    background: #f3f3f3;
    justify-content: space-between;
  }
  .SearchBar{
    display: flex; 
  }
  .search{
    border-radius: 0px!important;
    outline: 0;
    width: 300px;
    margin: 10px;
    text-indent: 30px;
    padding: 12px;
  }
	.sale-page-dropdown {
		width: 300px;
		margin: 0 10px;
	}
  #outer-main{
    justify-content: space-between;
    padding: 10px 0px;
    display: flex;
    margin: auto;
  }
  .search-icon{
    width: 15px;
    position: absolute;
    margin: 26px 25px;
    cursor: pointer;
    font-size: 18px;
  }
  .all-btns{
    width: 64%;
    display: flex;
    margin-top: 7px;
  }
  .button-main{
    font-size: 16px!important;
    border-radius: 0px!important;
    width: 100% !important;
    background: transparent!important;
    color: #616468!important;
    border: 1px solid rgb(201, 199, 199);
  }
  .button-main:hover{
    background: transparent;
    border: 1px solid rgb(201, 199, 199);
    color: #212529;
  }
  .drop-section{
    background: #f3f3f3;
    margin-top: 5px;
    border-radius: 0px;
  }
  .main-btn{
    display: none;
  }

}




@font-face {
  font-family: Raine-HorneLight;
  /* src: url(/public/Font/Raine-HorneLight.otf); */
  src:url(../../Fonts/Raine-HorneLight.otf)

}

@font-face {
  font-family: Raine-HorneRegular;
  src: url(../../Fonts/Raine-HorneRegular.otf);
}



#salemaindiv {
  font-family: Raine-HorneLight;
}

.Cardtitle {
  font-size: 17px;
  font-weight: 500;
  max-height: 43px;
  margin-bottom: 10px;
  overflow: hidden;
  height: 50px;
  
  line-height: 22px;
  font-family: California;
  color: rgb(70, 70, 92);
}

.price-details {
  color: rgb(70, 70, 92) !important;
  font-size: 15px !important;
}

.price-all {
  font-size: 16px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  /* Customize the background color */
  color: #f6ba16;
  /* Customize the arrow color */
  border: 2px solid #000;
  /* Customize the arrow border */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-arrow:hover {
  background-color: #ffcc4c;
  /* Customize the hover background color */
  color: #fff;
  /* Customize the hover arrow color */
}

.custom-prev {
  left: -25px;
  /* Adjust the position of the previous arrow */
}

.custom-next {
  right: -30px;
}

#itemslider {
  border: 2px solid black;

}

#pro-heading {
  margin-left: 5px;
  margin-bottom: 30px;
  color: rgb(107, 107, 107);
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 0px !important;
  color: #333;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #eee;
}

.pagination .active a {
  background-color: #fcaf17;
  color: #fff;
  border-color: #fcaf17;
}

#drop-down-filter {
  margin-top: 4px;
  width: 220px;
  /* top:140px; */
  right: 30px;
}



.incwidth {
  width: 195px;
}

#main-div-card {
  /* width: 300px; */
  border: none;
  box-shadow: 1px 1px 10px 2px rgb(206, 206, 206);
  /* font-family: California; */
  font-family: Raine-HorneRegular;
  color: #697a8a;
}

.details {
  font-size: 14px;

}

.next-button {
  margin-right: 10px;
  /* Adjust the margin as needed */
}

#drop-down-filter {
  margin-top: 2.5px;
  width: 220px;
  /* top:140px; */
  right: 30px;
}

#drop-pagination {
  display: flex;
 
  margin: auto;
  margin-bottom: 30px;
  margin-top: 70px;
  height: 55px;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;
  color: #616468;
  font-size: 19px;
  padding: 0px;
}

#filterresult {
  margin: 0px;
  padding-left: 0px !important;
  font-family: Raine-HorneRegular;
}

#filterselectbox {
  border: 1px solid grey;
  border-radius: 4px;
  border-radius: 0px !important;
  height: 35px;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 17px;
}

.incwidth {
  width: 195px;
}

#saleque {
  font-family: Raine-HorneRegular;
  color: #fff;
  font-size: 40px;
}

#sell-text-para {
  font-family: Raine-HorneLight;
  color: #fff;
  font-size: 18px;
}

#list-property {
  text-align: center;
  font-family: Raine-HorneRegular;
  color: #4d4d4f;
}



#sale-main {
  height: 1700px;
}

.testing {
  height: 30px !important;
  max-height: 30px !important;
  padding: 0px;
}




#salemaindiv {
  font-family: Raine-HorneLight;
}

.Cardtitle {
  font-size: 17px;
  font-weight: 900;
  max-height: 43px;
  margin-bottom: 10px;
  overflow: hidden;
  height: 50px;
  line-height: 22px;
  /* font-family: California; */
  font-family: Raine-HorneRegular;
  font-family: Raine-HorneLight;
  color: #4e5158;
}

.price-details {
  color: rgb(70, 70, 92) !important;
  font-size: 15px !important;
}

.price-all {
  color: #4e5158 !important;
  font-size: 16px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #fff;
  /* Customize the background color */
  color: #f6ba16;
  /* Customize the arrow color */
  border: 2px solid #000;
  /* Customize the arrow border */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-arrow:hover {
  background-color: #ffcc4c;
  /* Customize the hover background color */
  color: #fff;
  /* Customize the hover arrow color */
}

.custom-prev {
  left: -25px;
  /* Adjust the position of the previous arrow */
}

.custom-next {
  right: -30px;
}

#itemslider {
  border: 2px solid black;

}

.pl-16 {
  padding-left: 3rem;
  padding-right: 2rem;
}


#pro-heading {
  margin-left: 5px;
  
  margin-bottom: 30px;
  color: #504d4f !important;
}



.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 0px !important;
  color: #333;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #eee;
}

.pagination .active a {
  background-color: #fcaf17;
  color: #fff;
  border-color: #fcaf17;
}

#drop-down-filter {
  margin-top: 4px;
  width: 220px;
  /* top:140px; */
  right: 30px;
}



.incwidth {
  width: 195px;
}

#main-div-card {
  border: none;
  box-shadow: 1px 1px 10px 2px rgb(206, 206, 206);
  font-family: Raine-HorneRegular;
  color: #697a8a;
  overflow: hidden;
}

.details {
	color: #4e5158 !important;
	align-items:center;
	display:flex;
  font-size: 14px;

}

.next-button {
  margin-right: 10px;
  /* Adjust the margin as needed */
}

#drop-down-filter {
  margin-top: 2.5px;
  width: 220px;
  /* top:140px; */
  right: 30px;
}

#drop-pagination {
  display: flex;
  margin: auto;
  margin-bottom: 30px;
	align-items: center !important;
  margin-top: 26px;
  height: 45px;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;
  color: #616468;
  font-size: 19px;
  padding: 0px;
}

#filterresult {
  margin: 0px;
  padding-left: 0px !important;
  font-family: Raine-HorneRegular;
}

#filterselectbox {
  border: 1px solid grey;
  border-radius: 4px;
  border-radius: 0px !important;
  height: 35px;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 17px;
}

.incwidth {
  width: 195px;
}

#saleque {
  font-family: Raine-HorneRegular;
  color: #fff;
  font-size: 40px;
}

#sell-text-para {
  font-family: Raine-HorneLight;
  color: #fff;
  font-size: 18px;
}

#list-property {
  text-align: center !important;
  font-family: Raine-HorneLight !important;
  color: #4d4d4f;
}



#sale-main {
  height: 1700px;
}

.testing {
  height: 30px !important;
  max-height: 30px !important;
  padding: 0px;
}
@media screen and (min-width:320px) and (max-width:380px) {
	#pro-heading {
    font-size: 19px;
    text-align: center;
}
  div#salemaindiv {
    padding: 0px;
    margin: 30px auto;
  }
	.cards-change-margin{
	width: 87%;
	margin: auto;
}
}

@media only screen and (max-width:576px){
	.cardspadding{
	padding:0px !important;
	}
	.change-margin{
	padding:18px !important;
	}
	.bedWidth{
width:17% !important;
}
	.bathtubwidth{
	width:7% !important;
	}
	.squarftwidth{
width:46%;
}

}
@media screen and (min-width:300px) and (max-width:370px){
.bedWidth{
width:30% !important;
}
	.bathtubwidth{
	width:23% !important;
	}
.squarftwidth{
width:55% !important;
}
}
@media screen and (min-width:370px) and (max-width:385px){
.bedWidth{
width:21% !important;
}
	.bathtubwidth{
	width:20% !important;
	}
.squarftwidth{
width:38% !important;
}
}
@media screen and (min-width:385px) and (max-width:419px){
.bedWidth{
width:21% !important;
}
	.bathtubwidth{
	width:20% !important;
	}
.squarftwidth{
width:33% !important;
}
}
@media screen and (min-width:420px) and (max-width:470px){
.bedWidth,.bathtubwidth{
width:20% !important;
}
.squarftwidth{
width:29% !important;
}
}
@media screen and (min-width:380px) and (max-width:576px) {
 	#pro-heading {
    font-size: 19px;
    text-align: center;
}
  div#salemaindiv {
    padding: 0px;
    margin: 30px auto;
  }
	.cards-change-margin{
	margin: auto;
		width: 87%;
}
}

@media screen and (min-width:460px) and (max-width:768px) {
	h3#pro-heading {
    margin: 0px !IMPORTANT;
    text-align: center;
    font-size: 25px;
  }
}
@media only screen and (max-width:768px) {
	#pro-heading{
font-family: Raine-HorneLight !important;
	font-size: 25px !important;
}
div#salemaindiv {
    margin: 30px auto !important;
  }
  .row.pl-16 {
    padding: 0px;
  }
  div#drop-pagination {
    height: 50px !important;
    text-align: center;
  }

  div#drop-down-filter {
    margin: 0px !important;
  }

  div#main-div-card {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
	.icons_gap{
gap:15;
}
	#pro-heading{
font-family: Raine-HorneLight !important;
	font-size: 30px !important;
}
	#salemaindiv {
	 width:90% !important;
    margin: 60px auto;
  }
}

.result-info.pt-1.mt-2.col {
    padding-top: 0px !important;
}

div#drop-pagination {
    padding-top: 0px !important;
}

@media only screen and (max-width: 375px){
	.cards_new_icon{
	height:22px;
	width: 22px
}
	.cards_new_alter{
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top:30px;
	margin-bottom:30px;
}
	.font_change_cards{
		font-size: 14px;
	}
	.additional_div{
	height: 5px;
}
}