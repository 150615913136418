#customers td,
#customers th {
  font-size: 18px;
  color: grey;
  font-family: 'Regular';
  height: 25px;
  line-height: 25px;
  border-bottom: 1px solid #ebebeb;
}

.text-justify > h3 {
	font-family: Raine-HorneLight !important;
	    font-size: 16px !important;
	    font-weight: 400 !important;
        color: #212529 !important;
}	
.j5dj8e{
font-family: Raine-HorneLight !important;
	font-size: 35px !important;
	color: #504d4f !important;
}

#trustdiv{

  background: #f7f7f7;
}
#draytoGallary{
	font-family: Raine-HorneLight !important;
}

.i4g66fds4{
	padding: 10px;
	height: 180px;
	}

.hovon {
    font-family: "Raine-HorneLight"!important;
    width: 100%;
    border: none;
    background: #a7a9ac !important;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    height: 40px;
}

.hovon:hover{
	color: #fdba31;
}
@media only screen and (max-width: 576px){
	
	.i4g66fds4{
	height: min-content !important;
	}
	.mymediaclass{
	padding:0px !important;
	}
	
	
}

#customers tbody tr td{
  vertical-align: baseline;
  font: 14px Verdana, Geneva, Tahoma, sans-serif;
  font-family: 'PT Sans', sans-serif;
} 
#customers tbody tr{
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebebeb;
} 

#maincontent {
  
  font: 14px Verdana, Geneva, Tahoma, sans-serif;
  overflow: scroll;
  display: inline-flex;
  width: 100%;
  height: 700px;
  background: #f7f7f7;
  border-radius: 4px;
  border-radius: 0px !important;
}

#innnermaincontent {
  padding: 20px 15px;
  margin-top: 50px;
  /* float: left; */
  background: #fff;
  padding: 20px 15px;
  border-radius: 4px;
  border-radius: 0px !important;
  width: calc(100% - 450px);
  padding: 16px 20px;
  margin-left: 170px;
  width: 55%;
  background-color: white;
  border: 0;
  /* font-size: 100%;
  font: inherit;
  vertical-align: baseline; */
}

.dveti{
	font-weight: 500;
font-family: Raine-Horneregular !important;
    font-size: 20px !important;
}

.huye{
font-size: 16px;
font-family: Raine-HorneLight;
}

.joy{
font-size: 16px;
margin-bottom: 0px;
font-family: Raine-HorneLight;
}

.dwet{
		font-size: 18px;
    margin-top: 15px;
    margin-bottom: 0px;
    font-family: Raine-HorneRegular;
		
	}

@media screen and (min-width: 992px){
.jkwqy{
	  display: flex;
    justify-content: center;
    align-items: center;
	  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
	 	.newdev-change-margin{
		margin-bottom: 30px !important; 
	}
	.dveti{
    font-size: 18px !important;
		line-height: normal;
		
}
.mymediaclass{
  width: 50%;
  display: flex;
}
	.dwet{
		font-size: 16px !important;
		
	}
	.huye,.joy{
font-size: 15px !important;
}
	


}



@media only screen and (max-width: 768px) {
	.j5dj8e{
        font-family: Raine-HorneLight !important;
	    font-size: 25px !important;
		text-align:center;
}
	.dwet{
		font-size: 18px !important;
	}
	.hovon{
   margin-top: auto;
	width: 100%;
	border: none;
	color: rgb(78, 81, 80);
	font-size: 18px;
	font-weight: 500;
	height: 40px;
}
.dveti{
    font-size: 18px !important;
	line-height: normal;
}
	.huye{
		font-size:18px;
			}
	
	.joy{
	font-size: 15px;
	}
	.j5dj8e{
	font-size: 35px;
}

	
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
		.j5dj8e{
        font-family: Raine-HorneLight !important;
	    font-size: 30px !important;
		text-align:center;
}
}

@media only screen and (max-width: 575.98px) {
 
	.dveti{
	font-weight: 500;
	letter-spacing: .3px;
    font-size: 18px !important;
}

	.huye{
		font-size:18px;
			}
	.joy{
	font-size: 15px;
	}
	
}



/*                      ******************************   career page css *****************                 */

  @media screen and (min-width: 481px) and (max-width: 768px) {
	  	.newdev-change-margin{
		margin-bottom: 30px !important; 
	}
	  .i4g66fds4{
	height: min-content !important;
	}

	  
	  #ilabh,#i786h,#it60f {
    margin: 0px 0px 0px 40px;
}
	  
	  
	  /*                              **************** About us ****************                        */
	  
	  
	  #ies9s{
	  width: 100% !important;
	  }
	  .ne5763{
			padding: 0px;
	  }
	  #ies9s{
	  padding: 0px !important;
	  }
	  
	  

	  
	    /*                           **************** Advisory *****************                         */
	  
	  
	  #ixkhcf{
	      padding: 0px 20px 0px 10px !important;
	  }
	  
	  .mar563{
	  padding: 0px 20px 0px 20px !important;
	  }
	  
	    /*                                   ************** joinus  **********                        */
	  #ibul4  {
		  width:61% !important;
	  }
	    /*                                   ************** sell  **********                        */
	  .sec3-image-div {
    margin: 0px 20px 2rem 20px !important;
}
	  #i84tq{
	  padding: 0px !important;
		  margin-top: 10px !important;
		  margin-right: 0px !important;
	  }
	  
	  
	  /*                            *************** property management ****************         */
	  
	  
	  #iw922{
	  padding: 0px !important;
	  }
	  
	  
	 	    /*                                   ************** conatct  **********                        */ 

	  .phonepadding{
		  padding-bottom:10px !important;
	  }
	  #iwe1c{
	  text-align:center !important;
	  }
  }


/*                                   ************** our team **********                        */

#ijpf8{
margin-bottom:0px !important;
}

