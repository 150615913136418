/* styles.css */
.centered-modal {
    position: fixed;
    font-family: Raine-HorneRegular;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    /* Ensure it appears above other content */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    backdrop-filter: blur(5px);
    /* Apply blur to the background */
    padding: 20px;
    /* Add padding for the modal content */
    border-radius: 8px;
}

.popup_form_update{
	width: 87%;
	margin: auto;
}
.home-page-popup {
	width: 100% !important;
	margin: auto;
}

.pop-subs{
	background: transparent;
	border: 1px solid rgb(80, 77, 79);
	color: #4d4d4f;
	border-radius: 0px !important;
}

.pop-subs:hover{
	background: #504d4f!important;
	border: 1px solid rgb(80, 77, 79);
	color: #fff;
	border-radius: 0px !important;
}

#popheading {
    font-family: Raine-HorneLight !important;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: auto;
}

#subheading {
    /* border:2px solid red; */
    font-family: Raine-HorneRegular;
    text-align: center;
    font-size: 15px;
}

#two-para {
    /* border:2px solid red; */
    color: #504d4f;
    font-size: 14px;


    font-family: Raine-HorneLight;
    width: 420px;
    margin: auto;
}

#close {
    background-color: #a7a2a5;
    width: 130px;
    font-family: Raine-HorneRegular;
}

#subsicrbe {
    font-family: Raine-HorneRegular;

    background-color: #fcaf17;
    width: 130px;
}

.blackdot::before {
    content: '\2022';
    /* Unicode character for a bullet point */
    color: black;
    /* Change the color to black */
    margin-right: 10px;
    /* Adjust the spacing as needed */
}

#modelmain {
    overflow: hidden;
}
#summs {
    list-style-type: none;
}
#op{
/* height: 200px; */
}
..css-1qhwcc5-control{
	height: 50px !important;
}



@media only screen and (max-width: 576px){
	.modal-content{
		width: 94% !important;
		margin: auto !important;
	}
	.popup_form_update{
	width: 96%;
	margin: auto;
}
	#popup_btn{
		width: 160px !important;
		padding: 2px 5px !important;
		font-size: 18px !important;
	}
}